import Sentry from 'components/sentry';
import { get_api_weixin_jsapi_signature as getApiWeixinJsapiSignature } from 'components/api';
export default class WXJSSDK {
  /**
     * 获取微信 wx.config 所需信息
     * @return {Promise}
     */
  static getWXJSConfig(wx) {
    const url = encodeURIComponent(window.location.href.replace(window.location.hash, ''));
    return getApiWeixinJsapiSignature({ url })
      .then((res) => {
        wx.config({
          debug: false,
          appId: res.data.app_id,
          timestamp: res.data.timestamp,
          nonceStr: res.data.nonce_str,
          signature: res.data.signature,
          openTagList: [
            'wx-open-subscribe',
          ],
          jsApiList: [
            'updateAppMessageShareData',
            'updateTimelineShareData',
            'onMenuShareTimeline',
            'onMenuShareAppMessage',
          ],
        });
      });
  }

  constructor() {
    // 微信 JSSDK 地址
    this.WX_JSSDK_URL = 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js';
    // 由于网络连同性问题，下面这个是备用地址
    this.WX_JSSDK_URL_FALLBACK = 'https://res2.wx.qq.com/open/js/jweixin-1.6.0.js';

    // 查询页面中的微信 JSSDK 代码
    this.script = document.querySelector('[src^="https://res.wx.qq.com/open/js/jweixin"]');

    this.wxjssdkReady = this.install();
  }

  /**
     * 加载微信 JSSDK
     * @return {Promise}
     */
  install() {
    return new Promise((resolve, reject) => {
      const { script } = this;

      // script 挂载成功，调用 wx config
      const scriptOnLoad = (event) => {
        const { wx } = window;

        WXJSSDK.getWXJSConfig(wx)
          .then(resp => resolve())
          .catch((error) => {
            reject(error);
            Sentry.captureException(error);
          });
      };

      // script 加载失败，换备用地址再试一次
      const scriptOnError = (event) => {
        // 备用地址还失败，则上报
        if (this.script.src === this.WX_JSSDK_URL_FALLBACK) {
          const error = new Error(`script ${this.script.src} load error`);
          reject(error);
          Sentry.captureException(error);
          return;
        }

        // 替换备用地址
        this.script.src = this.WX_JSSDK_URL_FALLBACK;
      };

      if (script) {
        script.readyState === 'complete'
          ? scriptOnLoad()
          : script.addEventListener('load', scriptOnLoad);

        return;
      }

      const newScript = document.createElement('script');
      newScript.src = this.WX_JSSDK_URL;
      this.script = newScript;

      newScript.addEventListener('load', scriptOnLoad, false);
      newScript.addEventListener('error', scriptOnError, false);
      if (process.env.TARO_ENV !== 'weapp') {
        document.head.append(this.script);
      }
    });
  }

  /**
     * 设置分享的内容
     * @public
     * @param title
     * @param desc
     * @param link
     * @param imgUrl
     */
  setShare({ title, desc, link, imgUrl }) {
    this.wxjssdkReady.then(() => {
      const { wx } = window;
      // jssdk 1.4 的 api
      wx.updateAppMessageShareData({ title, link, imgUrl, desc });
      wx.updateTimelineShareData({ title, link, imgUrl });

    //   // jssdk 1.2 的 api
    //   wx.onMenuShareAppMessage({ title, link, imgUrl, desc });
    //   wx.onMenuShareTimeline({ title, link, imgUrl });
    });
  }
}
